import React from 'react'
import { useUser, useSetAcceptedTOS } from '@/state/user/hooks'
import Modal from '@/ui/Modal'
import Box from '@/ui/Box'
import Button from '@/ui/Button'
import Text from '@/ui/Text'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { styled } from '@/ui/theme'
import { keyframes } from '@stitches/react'

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

export const StyledContent = styled(DialogPrimitive.Content, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 3,
  minWidth: 200,
  maxHeight: '85vh',
  padding: '$4',
  marginTop: '-5vh',
  borderRadius: '14px',
  backgroundColor: '$slate3',
  willChange: 'transform',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': {
    outline: 'none',
  },
})

const TOSModal: React.FC = () => {
  const { acceptedTOS } = useUser()
  const setAcceptedTOS = useSetAcceptedTOS()

  return (
    <Modal open={!acceptedTOS}>
      <StyledContent css={{ width: 350, backgroundColor: '$slate4' }}>
        <Text color="hiContrast" css={{ mb: '$3' }}>
          By using this web application, you agree to the Primitive{' '}
          <a href="https://primitive.xyz/terms-of-service.pdf" target="_blank" rel="noreferrer">
            <Text as="span" color="accent">
              Terms of Service.
            </Text>
          </a>
        </Text>
        <Box css={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button
            variant="solid"
            color="primary"
            size="md"
            css={{ '&:focus': { borderColor: 'transparent' } }}
            onClick={() => setAcceptedTOS(!acceptedTOS)}
          >
            Accept
          </Button>
        </Box>
      </StyledContent>
    </Modal>
  )
}

export default TOSModal
