import React, { useCallback, useMemo, ReactNode, useRef, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseWei, Percentage, Wei, Time } from 'web3-units'
import { Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { Pool, PoolSides, Swaps, Calibration } from '@primitivefi/rmm-sdk'
import { useNavigate } from '@/hooks/useNavigate'
import { useEngineEntity } from '@/hooks/engine/useEngineEntity'
import { validateSigmaInput } from '@/utils/validateSigmaInput'
import { validateStrikeInput } from '@/utils/validateStrikeInput'
import { validateExpiryInput } from '@/utils/validateExpiryInput'
import { useEnginePools } from '@/hooks/engine/useEnginePools'
import { useTokenAllowance, useTokenBalance } from '@/hooks/data'
import { useRmmProtocol } from '@/hooks/useRmmProtocol'

import {
  typeInput,
  clearInput,
  typePriceInput,
  Field,
  AddStatus,
  setStatus,
  setLiquidityError,
  clearLiquidityError,
  setFieldErrors,
} from './actions'
import { ADD_LIQUIDITY_TEXT } from '@/state/add/reducer'
import { AppDispatch, AppState } from '../index'
import { AddState } from './reducer'
import { isWETH } from '@/utils/isWETH'
import { BigNumber } from 'ethers'

export const useAdd = (): AddState => {
  const state = useSelector<AppState, AppState['add']>((state) => state.add)
  return state
}

export const useClearAdd = (): (() => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(() => {
    dispatch(clearInput())
  }, [dispatch])
}

/**
 * @notice Triggered when a status update is called
 */
export const useSetStatus = (): ((status: AddStatus) => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (status) => {
      dispatch(setStatus(status))
    },
    [dispatch]
  )
}

export function useAddActionHandlers(): {
  onRiskyInput: (typedValue: string) => void
  onStableInput: (typedValue: string) => void
  onRMMInput: (typedValue: string) => void
  onPriceInput: (typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onRiskyInput = useCallback(
    (typedValue: string) => {
      dispatch(
        typeInput({
          field: Field.RISKY,
          typedValue: typedValue,
        })
      )
    },
    [dispatch]
  )

  const onStableInput = useCallback(
    (typedValue: string) => {
      dispatch(
        typeInput({
          field: Field.STABLE,
          typedValue,
        })
      )
    },
    [dispatch]
  )

  const onRMMInput = useCallback(
    (typedValue: string) => {
      dispatch(
        typeInput({
          field: Field.RMM_LP,
          typedValue,
        })
      )
    },
    [dispatch]
  )

  const onPriceInput = useCallback(
    (typedValue: string) => {
      dispatch(typePriceInput({ typedValue }))
    },
    [dispatch]
  )

  return {
    onRiskyInput,
    onStableInput,
    onRMMInput,
    onPriceInput,
  }
}

export const useSetLiquidityError = (): ((label, errorMessage) => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (label: string, errorMessage: string) => {
      dispatch(setLiquidityError({ label, errorMessage }))
    },
    [dispatch]
  )
}

export const useClearLiquidityError = (): ((label) => void) => {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (label: string) => {
      dispatch(clearLiquidityError(label))
    },
    [dispatch]
  )
}
export const useSetFieldErrors = (): ((field: string, errors: string[]) => void) => {
  const dispatch = useDispatch()

  return useCallback(
    (field, errors) => {
      dispatch(
        setFieldErrors({
          field,
          errors: errors ?? [],
        })
      )
    },
    [dispatch]
  )
}
const emptyDependentFields = {
  RISKY: undefined,
  STABLE: undefined,
  RMM_LP: undefined,
}

export interface DerivedAddInfo {
  dependentFields: { [field in Field]?: Wei }
  tokenBalances: { [field in Field]?: Wei }
  pool?: Pool
  calibration?: Calibration
  pools?: Pool[]
  totalValue?: Wei
  liquidityMinted?: Wei
  poolTokenPercentage?: Percentage
  error?: ReactNode
  poolIsValid?: boolean
  poolIsCreated?: boolean
  buttonText?: string
  readyToSubmit?: boolean
}

const DerivedInfoContext = createContext<DerivedAddInfo>({} as any as DerivedAddInfo)

export const useDerivedAddInfo = () => useContext(DerivedInfoContext)
export const DerivedInfoProvider = DerivedInfoContext.Provider

export const useDerivedAddInfoRoot = (): DerivedAddInfo => {
  const { account, library } = useWeb3React()
  const navigate = useNavigate()
  const { risky, stable, expiry, strike, sigma, gamma } = navigate.getAddLiquidityRouteParams()
  const engine = useEngineEntity(risky, stable)
  const clearAdd = useClearAdd()
  const { data: rmm } = useRmmProtocol()

  const { data: pools } = useEnginePools(engine)

  const { independentField, typedValue, priceTypedValue } = useAdd()

  const { data: riskyBalance } = useTokenBalance(engine?.risky, account, library)
  const { data: stableBalance } = useTokenBalance(engine?.stable, account, library)

  const { data: riskyAllowance } = useTokenAllowance(engine?.risky, account, rmm?.connection?.addresses.primitiveManager)
  const { data: stableAllowance } = useTokenAllowance(engine?.stable, account, rmm?.connection?.addresses.primitiveManager)

  useEffect(() => {
    clearAdd()
  }, [risky, stable, expiry, strike, sigma, gamma])

  const calibration = useMemo(() => {
    if (engine) {
      if (
        strike &&
        expiry &&
        sigma &&
        gamma &&
        validateStrikeInput(strike) &&
        validateExpiryInput(expiry) &&
        validateSigmaInput(sigma) &&
        validateSigmaInput(gamma)
      ) {
        const strikeWei = parseWei(strike, engine.stable.decimals)
        return new Calibration(engine?.factory, engine?.risky, engine?.stable, strikeWei.toString(), sigma, expiry, gamma)
      }
    }
  }, [engine, strike, expiry, sigma, gamma])

  const poolIsCreated = useMemo(
    () => pools && pools?.filter((p) => p?.poolId === calibration?.poolId).length > 0,
    [calibration, pools]
  )

  const pool = useMemo(() => {
    if (calibration && pools) {
      const poolId = calibration.poolId
      const [realPool] = pools.filter((pool) => pool.poolId === poolId)

      if (realPool) {
        return realPool
      }

      if (priceTypedValue) {
        return Pool.fromReferencePrice(
          parseFloat(priceTypedValue),
          calibration.factory,
          calibration.risky,
          calibration.stable,
          {
            strike: calibration.strike.toString(),
            sigma: calibration.sigma.toString(),
            maturity: calibration.maturity.toString(),
            gamma: calibration.gamma.toString(),
            lastTimestamp: Time.now.toString(),
          },
          calibration.chainId,
          undefined, // undefined reserves -> computed using ref price in constructor
          undefined // pool invariant
        )
      }
    }
  }, [calibration, priceTypedValue, pools])

  const expiryValid = validateExpiryInput(expiry)

  const poolIsValid = pool?.reserveRisky?.gt(0) && pool?.reserveStable?.gt(0) ? true : false

  const dependentFields = useMemo(() => {
    if (!pool || !typedValue || !engine) return emptyDependentFields
    let poolSide: PoolSides
    let independentToken: Token
    switch (independentField) {
      case Field.RISKY:
        independentToken = engine?.risky
        poolSide = PoolSides.RISKY
        break
      case Field.STABLE:
        independentToken = engine?.stable
        poolSide = PoolSides.STABLE
        break
      default:
        independentToken = pool
        poolSide = PoolSides.RMM_LP
        break
    }

    // Get the independent value
    const independentValue = parseWei(typedValue, independentToken.decimals)
    if (pool.liquidity.raw.isZero()) return emptyDependentFields
    // Compute other inputs using the independent value
    const { delRisky, delStable, delLiquidity } = pool.liquidityQuote(independentValue, poolSide)

    // ----- !! Extremely Important Code Below !! -----
    const lowerDecimalToken =
      pool.reserveRisky.decimals === pool.reserveStable.decimals
        ? 'NONE'
        : pool.reserveRisky.decimals > pool.reserveStable.decimals
        ? 'STABLE'
        : 'RISKY'

    let adjustedDelLiquidity = delLiquidity
    let adjustedDelRisky = delRisky
    let adjustedDelStable = delStable
    if (lowerDecimalToken !== 'NONE') {
      const lowerDecimalReserve = lowerDecimalToken === 'RISKY' ? pool.reserveRisky : pool.reserveStable
      // This is because liquidity has 18 decimals. A token with lower decimals can introduce the scenario where the lowest unit
      // of liquidity share (1e-18) entitles the owner to a fraction of the token at more decimal places than the token has.
      // For example, 1 wei of liquidity share (1e-18) might entitle someone to 0.000000001 USDC.
      // However, in the EVM this is equal to 0 USDC because
      // it only has 6 decimal places.
      // Therefore, liquidity to mint must be adjusted. After liquidity is adjusted, the allocate amounts
      // must also be readjusted to match the adjusted liquidity.

      const factor = parseWei(1, pool.liquidity.decimals + lowerDecimalReserve.decimals)
      const liquidityPrecisionFactor = factor.mul(lowerDecimalReserve).div(pool.liquidity) // 1e(18 + lowerReserveDecimal) * lowerReserveDecimal / 18 = lowerReserveDecimal * 2
      const liquidityAdjustmentFactor = parseWei(1, liquidityPrecisionFactor.decimals).div(liquidityPrecisionFactor) // 1e(liquidityPrecision) / liquidityPrecision = 1 / precisionFactor
      adjustedDelLiquidity = delLiquidity.div(liquidityAdjustmentFactor).mul(liquidityAdjustmentFactor) // 1e18 / adjustmentFactor * adjustmentFactor = rounded down to precision of adjustmentFactor
      adjustedDelRisky = pool.reserveRisky.mul(adjustedDelLiquidity).div(pool.liquidity).add(1) // readjusted to adjusted liquidity amount, with decimals of risky, rounded up
      adjustedDelStable = pool.reserveStable.mul(adjustedDelLiquidity).div(pool.liquidity).add(1) // readjusted to adjusted liquidity amount, with decimals of stable, rounded up
    }

    // ----- End Extremely Important Code -----

    return {
      RISKY: adjustedDelRisky,
      STABLE: adjustedDelStable,
      RMM_LP: adjustedDelLiquidity,
    }
  }, [typedValue, risky, stable])

  // const buttonText = useMemo(() => {
  //   const { RISKY: delRisky, STABLE: delStable } = dependentFields
  //   if (!account) return 'Connect Wallet'
  //   if (!expiry) return ADD_LIQUIDITY_TEXT.EXPIRY
  //   if (!expiryValid) return ADD_LIQUIDITY_TEXT.INVALID_EXPIRY
  //   if (!strike) return ADD_LIQUIDITY_TEXT.STRIKE
  //   if (!sigma) return ADD_LIQUIDITY_TEXT.VOLATILITY
  //   if (!delRisky || !delStable || delRisky.lte(0) || delRisky.lte(0)) return ADD_LIQUIDITY_TEXT.LIQUIDITY

  //   if (delRisky.gt(riskyBalance ?? parseWei(0, engine?.risky.decimals)))
  //     return ADD_LIQUIDITY_TEXT.INSUFFICIENT_BALANCE.replace('Token', engine?.risky?.symbol ?? 'Token')

  //   if (delStable.gt(stableBalance ?? parseWei(0, engine?.stable.decimals)))
  //     return ADD_LIQUIDITY_TEXT.INSUFFICIENT_BALANCE.replace('Token', engine?.stable?.symbol ?? 'Token')

  //   if (stableAllowance && stableAllowance?.lt(delStable) && !isWETH(pool?.stable?.address, pool?.chainId))
  //     return `Approve ${engine?.stable?.symbol}`

  //   if (riskyAllowance && riskyAllowance?.lt(delRisky) && !isWETH(pool?.risky?.address, pool?.chainId))
  //     return `Approve ${engine?.risky?.symbol}`

  //   return ADD_LIQUIDITY_TEXT.ADD
  // }, [
  //   expiry,
  //   strike,
  //   sigma,
  //   dependentFields.RISKY,
  //   dependentFields.STABLE,
  //   riskyBalance,
  //   stableBalance,
  //   riskyAllowance,
  //   stableAllowance,
  // ])

  const readyToSubmit = Boolean(
    riskyBalance &&
      stableBalance &&
      dependentFields.RISKY &&
      dependentFields.STABLE &&
      dependentFields.RISKY.gt(0) &&
      dependentFields.STABLE.gt(0) &&
      dependentFields.RISKY.lte(riskyBalance) &&
      dependentFields.STABLE.lte(stableBalance)
  )

  const tokenBalances = {
    [Field.RISKY]: riskyBalance,
    [Field.STABLE]: stableBalance,
  }

  const totalValue = useMemo(() => {
    if (dependentFields.RMM_LP && pool?.liquidity.gt(0))
      return pool
        .getCurrentLiquidityValue(pool?.referencePriceOfRisky?.float as any)
        .valuePerLiquidity.mul(dependentFields.RMM_LP)
    else return undefined
  }, [dependentFields])

  return {
    dependentFields,
    tokenBalances,
    totalValue,
    pool,
    calibration,
    liquidityMinted: dependentFields.RMM_LP,
    poolIsValid,
    pools,
    poolIsCreated,
    readyToSubmit,
  }
}
