import { Engine } from '@primitivefi/rmm-sdk'
import { useOnchainToken } from '@/hooks/data'
import { useMemo } from 'react'
import { useRmmProtocol } from '../useRmmProtocol'
import { WETH_ADDRESS } from '@/constants/index'
import { useActiveWeb3React } from '../web3'

/**
 * @notice Fetches the on-chain state the risky and stable tokens to build the Engine enitity
 */
export const useEngineEntity = (risky?: string, stable?: string): Engine | undefined => {
  const { chainId } = useActiveWeb3React()
  const riskyAddress = risky === 'ETH' || risky === 'WETH' ? WETH_ADDRESS[chainId ?? 4] : risky
  const { data: riskyToken } = useOnchainToken(riskyAddress)
  const { data: stableToken } = useOnchainToken(stable)
  const { data: rmm } = useRmmProtocol()

  const engine = useMemo(() => {
    if (riskyToken && stableToken && rmm) {
      return new Engine(rmm.connection.addresses.primitiveFactory, riskyToken, stableToken)
    } else return undefined
  }, [rmm?.connection.chainId, riskyToken?.chainId, stableToken?.chainId, riskyToken?.address, stableToken?.address])

  return engine
}
