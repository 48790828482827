import React, { useEffect } from 'react'
import Head from 'next/head'
import { useClearAdd } from '@/state/add/hooks'
import { useOpenChainSelectModal } from '@/state/modal/hooks'
import { useUser } from '@/state/user/hooks'
import { useActiveWeb3React } from '@/hooks/web3'
import { withStyled } from '@/ui/theme/utils'
import { Box } from '@/ui/Box'
import Button from '@/ui/Button'
import Heading from '@/ui/Heading'
import Icon from '@/ui/Icon'
import { useNavigate } from '@/hooks/useNavigate'
import { formatAmount } from '@/utils/formatAmount'
import TOSModal from '@/components/TOSModal'

type PageHeaderProps = {
  title: string | JSX.Element
  hasBackButton?: boolean
}

export const PageHeader = withStyled<typeof Box, PageHeaderProps>(
  'PageHeader',
  ({ title, hasBackButton, children, css, ...props }) => {
    const { toHome } = useNavigate()
    return (
      <Box as="header" display="flex" justify="space-between" css={{ mb: '$4', '@sm': {}, ...css }} {...props}>
        <Box display="flex" align="center" gap="2">
          {hasBackButton && (
            <Button size="md" rounded icon aria-label="Back Button" onClick={toHome} css={{ w: 30, h: 30, p: 0 }}>
              {' '}
              <Icon size="3xl" icon="CaretLeftIcon" />{' '}
            </Button>
          )}
          <Heading data-cy="page-title" as="h1" css={{ fontSize: '$xl' }}>
            {title}
          </Heading>
        </Box>

        <Box as="aside">{children}</Box>
      </Box>
    )
  }
)

type PageProps = {
  description?: string
  assetInfo?: { price: number; symbol: string }
  title?: string
  showBlur?: boolean
}

// TODO: Add image content urls
export const Page: React.FC<PageProps> = ({ title, description, assetInfo, children }) => {
  const clear = useClearAdd()
  const { preferredChain } = useUser()
  const { chainId } = useActiveWeb3React()
  const openChainSelectModal = useOpenChainSelectModal()
  const assetPrefix = assetInfo ? `${assetInfo.symbol} ` : ''
  const pageTitle = assetPrefix + (title ? `${title} | Primitive` : 'Primitive')

  useEffect(() => {
    clear()
  }, [])

  useEffect(() => {
    if (chainId && chainId !== preferredChain) {
      openChainSelectModal()
    }
  }, [chainId])

  return (
    <main>
      <Head>
        <link href="/fonts/style.css" rel="stylesheet" />
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        )}
        <meta property="og:image" content="" />
        <meta property="og:image:type" content="image/png" />
        <meta property="twitter:card" content="" />
      </Head>

      <Box
        css={{
          margin: 'auto',
          px: '$4',
          pt: '$16',
          '@lg': {
            minWidth: '720px',
            maxWidth: '1140px',
            px: '$24',
          },
          minHeight: '100vh - 68px',
        }}
      >
        <TOSModal />
        {children}
      </Box>
    </main>
  )
}

export default Page
