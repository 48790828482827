import { isValidMaturity } from '@primitivefi/rmm-sdk'
export const validateExpiryInput = (expiryInput = '') => {
  const sanitized = expiryInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  const inputMaturityRegEx = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)
  const valid =
    sanitized.length > 0 &&
    inputMaturityRegEx.test(sanitized) &&
    isValidMaturity(expiryInput) &&
    parseFloat(sanitized) > Date.now() / 1000
  return valid
}
