import useSWR, { SWRResponse } from 'swr'
import { Contract } from 'ethers'
import { useActiveWeb3React } from '@/hooks/web3'
import { Engine, Pool, computePoolId } from '@primitivefi/rmm-sdk'
import { useContract } from '@/hooks/data'
import { useKeepSWRDataLiveAsBlocksArrive } from '../data'
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall'
import { EthersRmm, poolify } from '@primitivefi/rmm-ethers'
import { utils } from 'ethers'
import { Provider } from '@ethersproject/providers'

export const NAUGHTY_CACHE: Record<any, boolean> = {}

if (globalThis) {
  globalThis['getNaughtyCache'] = () => NAUGHTY_CACHE
}

export async function getPoolURIs(library: Provider, poolIds: string[]) {
  const multicall = new Multicall({ ethersProvider: library, tryAggregate: true })
  const rmm = await EthersRmm.connect(library)
  const abiCoder = new utils.AbiCoder()

  const contractCallContext: ContractCallContext[] = [
    {
      reference: 'manager',
      contractAddress: rmm.connection.addresses.primitiveManager,
      abi: ['function uri(uint256 id) view returns (string)'],
      calls: poolIds.map((pid) => {
        return {
          reference: 'uriCall',
          methodName: 'uri',
          methodParameters: [pid],
        }
      }),
    },
  ]

  const results: ContractCallResults = await multicall.call(contractCallContext)
  const uris = results.results?.manager?.callsReturnContext ?? []

  const pools: Pool[] = uris
    .map((uri) => {
      if (uri.success) {
        const decoded = abiCoder.decode(['string'], uri.returnValues)
        const pool = poolify(decoded[0])
        pool.referencePriceOfRisky = pool.reportedPriceOfRisky
        return pool
      } else {
        NAUGHTY_CACHE[uri.methodParameters.toString()] = true
      }
    })
    .filter((p) => p && p.maturity.raw % 86400 === 0) as Pool[]

  return pools
}

export function getEnginePools(
  engineContract: Contract | null,
  engine: Engine | undefined,
  library: any
): () => Promise<Pool[]> {
  return async (): Promise<Pool[]> => {
    if (!engine || !engineContract) return [] as Pool[]
    const events = await engineContract.queryFilter(engineContract.filters.Create())

    const poolIds = events.map((log: any) => {
      const args = log.args
      const poolId = computePoolId(engine.address, args.strike, args.sigma, args.maturity, args.gamma)
      return poolId
    })

    const pools = await getPoolURIs(library, poolIds)
    return pools
  }
}

export function useEnginePools(
  engine: Engine | undefined,
  suspense = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): SWRResponse<Pool[], any> {
  const { chainId, library } = useActiveWeb3React()

  const engineContract = useContract(engine?.address, [
    'event Create(address indexed from, uint128 strike, uint32 sigma, uint32 indexed maturity, uint32 indexed gamma, uint256 delRisky, uint256 delStable, uint256 delLiquidity)',
  ])

  const shouldFetch = Boolean(engine?.address && engineContract && chainId)

  const result = useSWR(
    shouldFetch ? ['get-engine-pools', engine?.address, engine?.risky, engine?.stable] : null,
    getEnginePools(engineContract, engine, library),
    { suspense, dedupingInterval: 60 * 1000, refreshInterval: 60 * 1000 }
  )
  useKeepSWRDataLiveAsBlocksArrive(result.mutate)
  return result
}
